import React, { useEffect, useRef, useState } from "react";
import classNames from 'classnames/bind';
import styles from './KeyBoard.module.scss';
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

const cn = classNames.bind(styles);


const KeyBoard = ({ emailValue, setEmailValue, keyboardOpened, setKeyboardOpened }) => {
	const [layout, setLayout] = useState('default');
	const keyboard = useRef();

	useEffect(() => {
		if (keyboardOpened && !emailValue) {
			keyboard.current.clearInput()
		}
	}, [keyboardOpened])

	const onChange = (input) => {
		setEmailValue(input)
	};

	const onKeyPress = (button) => {
		if (button === "{enter}") {
			setTimeout(() => {
				setKeyboardOpened(false);
			}, 300);
		}
		if (button === "{shift}" || button === "{lock}") handleShift();
	};

	const onChangeInput = (event) => {
		const input = event.target.value;
		setEmailValue(input);
		keyboard.current.setEmailValue(input);
	};

	const handleShift = () => {
		const newLayoutName = layout === "default" ? "russian" : "default";
		setLayout(newLayoutName);
	};

	return (
		<div className={cn('keyboard')}>
			<input
				value={emailValue}
				onChange={onChangeInput}
				readOnly
			/>
			<Keyboard
				keyboardRef={r => (keyboard.current = r)}
				layoutName={layout}
				onChange={onChange}
				onKeyPress={onKeyPress}
				layout={{
					default: [
						"1 2 3 4 5 6 7 8 9 0",
						"q w e r t y u i o p",
						"a s d f g h j k l .",
						"z x c v b n m _ + -",
						"@ .ru {space} {bksp} {shift} {enter}"
					],
					russian: [
						"1 2 3 4 5 6 7 8 9 0",
						"й ц у к е н г ш щ з х ъ",
						"ф ы в а п р о л д ж э .",
						"я ч с м и т ь б ю _ + -",
						"@ .ru {space} {bksp} {shift} {enter}"
					]
				}}
				display={{
					'{shift}': layout === 'default' ? 'Русский' : 'English',
					'{space}': 'Пробел (Space) ',
					'{enter}': 'Ввод (Enter)',
					'{bksp}': '<- Удалить (Del)',
				}}
			/>
		</div>
	);
};

export default KeyBoard;
