import classNames from 'classnames/bind';
import styles from './BestPractices.module.scss';
import { NavLink } from 'react-router-dom';
import homeBtn from '../../assets/images/_UI/home-btn.png'
import returnBtn from '../../assets/images/_UI/return-btn.png'

const cn = classNames.bind(styles);

const BgWrapper = ({ children, title, isReturnActive = true, whereToUse = 'practices' }) => {

	return (
		<div className={cn('wrapper', 'flex', 'flex-col', 'items-center', 'relative', {
			'wrapper-practices': whereToUse === 'practices',
			'wrapper-projects': whereToUse === 'projects',
		})}>
			<div className='flex justify-center items-center relative w-full mb-16'>
				<div className='3xl:container flex flex-col 3xl:flex-row justify-center items-center relative w-full'>
					<img src={title} alt="" />
					<div className='my-4 2xl:my-0 flex items-center 2xl:absolute 2xl:top-1/2 2xl:right-6 3xl:-translate-y-1/2'>
						{isReturnActive
							?
							<NavLink to='/main-page'>
								<img src={returnBtn} alt="" className='cursor-pointer' />
							</NavLink>
							:
							<img src={returnBtn} alt="" className='cursor-pointer opacity-50' />
						}
						<NavLink to='/main-page'>
							<img src={homeBtn} alt="" className='ml-4 cursor-pointer' />
						</NavLink>
					</div>
				</div>

			</div>

			{children}

		</div>
	);
};

export default BgWrapper;
