import q1right from '../assets/images/FinanceHabits/mmf2023/q1right.jpg';
import q1wrong from '../assets/images/FinanceHabits/mmf2023/q1wrong.jpg';

import q2right from '../assets/images/FinanceHabits/mmf2023/q2right.jpg';
import q2wrong from '../assets/images/FinanceHabits/mmf2023/q2wrong.jpg';

import q3right from '../assets/images/FinanceHabits/mmf2023/q3right.jpg';
import q3wrong from '../assets/images/FinanceHabits/mmf2023/q3wrong.jpg';

import q4right from '../assets/images/FinanceHabits/mmf2023/q4right.jpg';
import q4wrong from '../assets/images/FinanceHabits/mmf2023/q4wrong.jpg';

import q5right from '../assets/images/FinanceHabits/mmf2023/q5right.jpg';
import q5wrong from '../assets/images/FinanceHabits/mmf2023/q5wrong.jpg';

import q6right from '../assets/images/FinanceHabits/mmf2023/q6right.webp';
import q6wrong from '../assets/images/FinanceHabits/mmf2023/q6wrong.webp';

import q7right from '../assets/images/FinanceHabits/mmf2023/q7right.png';
import q7wrong from '../assets/images/FinanceHabits/mmf2023/q7wrong.webp';
import q8right from '../assets/images/FinanceHabits/mmf2023/q8right.webp';
import q8wrong from '../assets/images/FinanceHabits/mmf2023/q8wrong.webp';

import q9right from '../assets/images/FinanceHabits/mmf2023/q9right.webp';
import q9wrong from '../assets/images/FinanceHabits/mmf2023/q9wrong.jpg';

export const questions = [
	{
		id: 1,
		title: 'Кто был министром финансов?',
		answers: [
			{
				isRight: true,
				image: q1right,
				text: 'Сергей Витте',
			},
			{
				isRight: false,
				image: q1wrong,
				text: 'Александр III',
			},
		],
	},
	{
		id: 2,
		title: 'Кто известный финансист?',
		answers: [
			{
				isRight: false,
				image: q2wrong,
				text: 'Вольфганг Амадей Моцарт',
			},
			{
				isRight: true,
				image: q2right,
				text: 'Адам Смит',
			},
		],
	},
	{
		id: 3,
		title: 'Кто всемирно известный инвестор?',
		answers: [
			{
				isRight: false,
				image: q3wrong,
				text: 'Вячеслав Тихонов',
			},
			{
				isRight: true,
				image: q3right,
				text: 'Уоррен Баффетт',
			},
		],
	},
	{
		id: 4,
		title: 'Какое животное присутствует в выражении трейдерского сленга?',
		answers: [
			{
				isRight: true,
				image: q4right,
				text: '«Резать лося», то есть фиксировать убытки',
			},
			{
				isRight: false,
				image: q4wrong,
				text: 'А это просто олень',
			},
		],
	},
	{
		id: 5,
		title: 'Какой грызун замечен в инвестиционном сленге?',
		answers: [
			{
				isRight: true,
				image: q5right,
				text: '«Хомяками» называют неопытных мелких инвесторов, которые совершают грубые ошибки',
			},
			{
				isRight: false,
				image: q5wrong,
				text: 'Это просто морская свинка',
			},
		],
	},
	{
		id: 6,
		title: 'Какую птицу используют для обозначения \n  труднопрогнозируемого события?',
		answers: [
			{
				isRight: true,
				image: q6right,
				text: 'Черный лебедь',
			},
			{
				isRight: false,
				image: q6wrong,
				text: 'Белый лебедь',
			},
		],
	},
	{
		id: 7,
		title: 'Выберите здание, в котором сегодня принимаются важные решения\n для экономики страны',
		answers: [
			{
				isRight: false,
				image: q7wrong,
				text: 'Русский музей, расположенный в Михайловском дворце в Санкт-Петербурге',
			},
			{
				isRight: true,
				image: q7right,
				text: 'Здание Банка России на Неглинной улице в Москве',
			},
		],
	},
	{
		id: 8,
		title: 'Выберите здание, вся история которого связана с деньгами',
		answers: [
			{
				isRight: false,
				image: q8wrong,
				text: 'Саввинское подворье',
			},
			{
				isRight: true,
				image: q8right,
				text: 'Здание ссудной казны',
			},
		],
	},
	{
		id: 9,
		title: 'Какое здание связано с финансами?',
		answers: [
			{
				isRight: false,
				image: q9wrong,
				text: 'Большой театр в Москве',
			},
			{
				isRight: true,
				image: q9right,
				text: 'Здание биржи в Санкт-Петербурге',
			},
		],
	},
];
