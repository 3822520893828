import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './BestProjects.module.scss';
import { mainData, modalData } from "../../data/bestProjects";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import BgWrapper from '../BestPractices/BgWrapper';
import title from '../../assets/images/BestProjects/title.svg'

const cn = classNames.bind(styles);

const BestProjects = () => {
	const [modal, setModal] = useState(false);
	const [imageModal, setImageModal] = useState(false);

	const [modalItem, setModalItem] = useState(null);
	const [currentImg, setCurrentImg] = useState(null);

	const [currentItemIndex, setCurrentItemIndex] = useState(0);
	const [currentImageIndex, setCurrentImageIndex] = useState(0);

	useEffect(() => {
		if (currentImg) {
			setImageModal(true)
			const index = modalItem.images.indexOf(currentImg)
			setCurrentImageIndex(index)
		} else {
			setImageModal(false)
		}
	}, [currentImg])

	useEffect(() => {
		if (!modal) setCurrentItemIndex(0)
		if (!imageModal) setCurrentImageIndex(0)
	}, [modal, imageModal])

	const openModal = (id) => {
		const [modalInfo] = modalData.filter(item => item.id === id);
		setModalItem(modalInfo)
		setCurrentItemIndex(modalInfo.id)
		setModal(true);
	};

	const changeImage = (val) => {
		const index = modalItem.images.indexOf(currentImg)
		if (val === 'prev' && currentImageIndex > 0) {
			const prevImg = modalItem.images[index - 1]
			setCurrentImg(prevImg)
		}
		if (val === 'prev' && currentImageIndex === 0) {
			const prevImg = modalItem.images[3]
			setCurrentImg(prevImg)
		}
		if (val === 'next' && currentImageIndex < 3) {
			const nextImg = modalItem.images[index + 1]
			setCurrentImg(nextImg)
		}
		if (val === 'next' && currentImageIndex === 3) {
			const nextImg = modalItem.images[0]
			setCurrentImg(nextImg)
		}
		if (typeof val === 'number') {
			setCurrentImg(modalItem.images[val])
		}
	}

	const changeProject = (val) => {
		const currentIndex = modalItem.id
		if (val === 'prev' && currentIndex > 1) {
			const [modalInfo] = modalData.filter(item => item.id === (currentIndex - 1));
			setModalItem(modalInfo)
			setCurrentItemIndex(modalInfo.id)
		}
		if (val === 'prev' && currentIndex === 1) {
			const [modalInfo] = modalData.filter(item => item.id === 10);
			setModalItem(modalInfo)
			setCurrentItemIndex(modalInfo.id)
		}
		if (val === 'next' && currentIndex < 10) {
			const [modalInfo] = modalData.filter(item => item.id === (currentIndex + 1));
			setModalItem(modalInfo)
			setCurrentItemIndex(modalInfo.id)
		}
		if (val === 'next' && currentIndex === 10) {
			const [modalInfo] = modalData.filter(item => item.id === 1);
			setModalItem(modalInfo)
			setCurrentItemIndex(modalInfo.id)
		}
	}

	return (
		<>
			<BgWrapper title={title} whereToUse='projects'>
				<div className={cn('content')}>
					{
						mainData.map((el) =>
							<div
								key={el.id}
								className={cn('project-card')}
								onClick={() => openModal(el.id)}
							>
								<img src={el.pic} alt="" />
								<h2>{el.name}</h2>
								<p>{el.location}</p>
							</div>
						)
					}
				</div>
			</BgWrapper>

			{modalItem &&
				<>
					{/* Основной модальник */}
					<ModalWindow
						windowState={modal}
						setModal={setModal}
						stripColor="#F95283"
						variant='projects'
						imageModal={imageModal}
						changeElement={changeProject}
						currentIndex={currentItemIndex}
					>
						<div className='flex flex-col xl:flex-row'>
							<div className='w-fit m-auto xl:m-0'>
								<div className={cn('images-container')}>
									{
									modalItem.images.map((el, index) =>

										<div className={cn({
											'small-img': [1, 2, 3].includes(index),
											'main-img': index === 0,
										}
										)}>
												<img
													src={el}
													alt=""
													key={index}
													// className={cn({
													// 	'small-img': [1, 2, 3].includes(index),
													// 	'main-img': index === 0,
													// })}
													onClick={() => setCurrentImg(el)}
											/>
											</div>

										)
									}
								</div>
							</div>
							<div className='flex-auto md:px-16 py-4'>
								<h2 className={cn('project-title')}>
									{modalItem.title}
								</h2>
								<p className={cn('project-place')}>
									{modalItem.place}
								</p>
								<p className={cn('project-description')}>
									{modalItem.description}
								</p>
								<div className={cn('project-description')}>
									<span>Благополучатели:</span> {modalItem.amount}
								</div>
							</div>
						</div>
					</ModalWindow>

					{/* Модальник для картинки */}
					<ModalWindow
						windowState={imageModal}
						setModal={setImageModal}
						variant='images'
						changeElement={changeImage}
						currentIndex={currentImageIndex}
					>
						<img src={currentImg} alt="" className='w-full h-full object-cover object-center rounded-2xl' />
					</ModalWindow>
				</>
			}
		</>
	);
};

export default BestProjects;
