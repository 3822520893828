import classNames from 'classnames/bind';
import styles from './SplashScreen.module.scss';
import loaderImg from '../../assets/images/_UI/loader.svg'
import clickFinger from '../../assets/images/_UI/click-finger.svg'
import clickRounds from '../../assets/images/_UI/click-rounds.svg'
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import { useSplashAnimation } from '../../hooks/useAnimation';

const cn = classNames.bind(styles);

const SplashScreen = () => {
	const navigate = useNavigate();

	const finger = useRef(null)
	const rounds = useRef(null)
	const loader = useRef(null)

	const { setIsButtonClicked } = useSplashAnimation(loader, finger, rounds)

	const goToNextPage = () => {
		setIsButtonClicked(true)
		setTimeout(() => {
			navigate('/main-page')
		}, 700);
	}

	return (
		<div
			className='bg-white w-screen h-screen flex justify-center items-center'
			onClick={() => goToNextPage()}
		>
			<div className={cn('loader-container')}>
				<img
					className='w-full h-full'
					src={loaderImg}
					alt=""
					ref={loader}
				/>
				<img
					className={cn('click-finger')}
					src={clickFinger}
					alt=""
					ref={finger}
				/>
				<img
					className={cn('click-rounds')}
					src={clickRounds}
					alt=""
					ref={rounds}
				/>
			</div>
		</div>
	)
}

export default SplashScreen
