import { useContext, useState, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import styles from './Game.module.scss';
import { NavLink } from 'react-router-dom';
import GameWrapper from './GameWrapper';
import loader from '../../assets/images/_UI/loader.svg'
import points from '../../assets/images/Game/points-decor.svg'
import owl from '../../assets/images/Game/owl.svg'
import phrase from '../../assets/images/Game/phrase.svg'
import owlGood from '../../assets/images/Game/owl-good.svg'
import owlBad from '../../assets/images/Game/owl-bad.svg'
import { UserScoreContext } from '../../context/ScoreContext';
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import { useGameTitleAnimation } from '../../hooks/useAnimation';
import KeyBoard from '../../components/KeyBoard/KeyBoard';
import html2pdf from "html2pdf.js/src";
import axios from 'axios';

const cn = classNames.bind(styles);
const address = 'https://app-dev.xn--80apaohbc3aw9e.xn--p1ai/api/wheel-life/send-result';

const FinanceQuizzes = () => {
	const [modal, setModal] = useState(false);
	const [keyboardOpened, setKeyboardOpened] = useState(false);
	const [email, setEmail] = useState('');
	const [greetingText, setGreetingText] = useState('');
	const [isEmailSent, setIsEmailSent] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const { totalScore, clearScore, clearAnswers } = useContext(UserScoreContext);

	const owlRef = useRef(null)
	const phraseRef = useRef(null)

	useGameTitleAnimation(owlRef, phraseRef)

	const currentUrl = window.location.href

	useEffect(() => {
		clearAnswers()

		if (currentUrl.includes('game-result')) {
			generateGreeting()

			return () => {
				clearScore()
			};
		}
	}, []);

	const generateGreeting = () => {
		const random = Math.random() * 10
		if (random < 3.3) {
			setGreetingText('Вы — эрудит!')
			return
		}
		if (random < 6.6 && random > 3.3) {
			setGreetingText('Вы — энциклопедист!')
			return
		}
		if (random < 10 && random > 6.6) {
			setGreetingText('Вы — гений!')
			return
		}
		setGreetingText('Вы — гений!')
	}

	const generatePDF = async () => {
		const element = document.getElementById('pdf-content');
		const opt = {
			margin: 10, // Отступы от края страницы
			filename: 'document.pdf', // Имя файла PDF
			image: { type: 'jpeg', quality: 0.98 }, // Качество изображения (если есть изображения в контенте)
			html2canvas: { scale: 2 }, // Масштабирование для html2canvas
			jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }, // Ориентация страницы и формат
		};
		const res = await html2pdf().set(opt).from(element).output('datauristring');
		const finalRes = res.split(',')[1];

		try {
			if (finalRes.length) {
				setIsLoading(true)
				const { data } = await axios.post(address, {
					"content": '123',
					"email": email,
					"pdf_content": finalRes,
				})

				if (data.status === 'success') {
					setIsEmailSent(true)
					setTimeout(() => {
						setIsEmailSent(false)
						setEmail('')
						setModal(false)
					}, 2000);
				}
				setIsLoading(false)
			}
		} catch (error) {
			setIsLoading(false)
			console.log(error);
		}
		// const res = html2pdf().set(opt).from(element).output('dataurlnewwindow');
		// console.log(res);
	}

	return (
		<GameWrapper returnLink={currentUrl.includes('game-result') ? '/game' : '/main-page'}>
			{
				!currentUrl.includes('game-result')
					?
					<div className='container overflow-hidden relative w-full flex flex-col items-center'>
						<h2 className={cn('game-title')}>
							Ответьте на <span className='text-[#2FADB3]'>9 вопросов</span> и определите<br />
							уровень своей финансовой эрудиции!
						</h2>
						<hr className={cn('w-1/2 border border-t border-black', 'game-hr')} />
						<p className={cn('game-description')}>
							В первой части нужно будет выбрать известных<br />
							финансистов, во второй части мы проверим, знаете ли вы<br />
							финансовый сленг, в третьей – разбираетесь ли в<br />
							финансовых учреждениях страны.
						</p>
						<p className={cn('game-question')}>Готовы?</p>
						<NavLink to='1' className={cn('game-link')}>
							СТАРТ
						</NavLink>

						<img src={points} alt="" className='hidden 2xl:block 2xl:absolute 2xl:top-24 2xl:left-24' />
						<img src={points} alt="" className='hidden 2xl:block 2xl:absolute 2xl:top-24 2xl:-right-16 2xl:w-56' />
						<img src={points} alt="" className='hidden 2xl:block 2xl:absolute 2xl:bottom-24 2xl:left-16 2xl:w-56' />
						<img ref={owlRef} src={owl} alt="" className='hidden 2xl:block 2xl:absolute 2xl:-bottom-32 2xl:right-0' />
						<img ref={phraseRef} src={phrase} alt="" className='hidden 2xl:block 2xl:absolute 2xl:-bottom-12 2xl:right-80' />
					</div>
					:

					<div className='relative w-full flex flex-col items-center 3xl:container'>
						<div id="pdf-content" className='flex flex-col items-center'>
							<p className={cn('result-top-text')}>Ваш результат</p>
							<p className={cn('result-score')}>{totalScore} из 9 правильных ответов</p>
							{
								totalScore > 4 &&
								<>
									<img src={owlGood} alt="" />
									<p className={cn('result-greeting-text')}>{greetingText}</p>
									<p className={cn('result-description')}>
										Ваши знания обескураживают! Чтобы пополнять багаж, регулярно заходите на<br />
										портал <span className="text-[#2FADB3]" href="#">моифинансы.рф</span>. Там много полезного даже для самого эрудированного<br />
										человека!
									</p>
								</>
							}
						</div>
						{
							totalScore > 4 &&
							<button
								className={cn('game-link')}
								onClick={() => setModal(true)}
							>
								ОТПРАВИТЬ РЕЗУЛЬТАТ НА ПОЧТУ
							</button>
						}
						{
							totalScore <= 4 &&
							<>
								<img src={owlBad} alt="" />
								<p className={cn('result-greeting-text')}>Ой! Мы не верим, что это <br />возможно!</p>
								<NavLink to='/game' className={cn('game-link')}>
									ПРОЙТИ ЕЩЕ РАЗ
								</NavLink>
							</>
						}

						<img src={points} alt="" className='hidden 2xl:block 2xl:absolute 2xl:top-24 2xl:left-24' />
						<img src={points} alt="" className='hidden 2xl:block 2xl:absolute 2xl:bottom-24 2xl:-right-16 2xl:w-56' />
					</div>
			}

			<ModalWindow
				windowState={modal}
				setModal={setModal}
				keyboardOpened={keyboardOpened}
				stripColor="#014F9E"
			>
				<div className='relative h-1/3'>
					{
						!isEmailSent
							?
							<>
								<img src={loader} alt="" className='opacity-30' />
								<input
									className={cn('input-email')}
									placeholder='Введите e-mail'
									value={email}
									readOnly
									onChange={(e) => setEmail(e.target.value)}
									onFocus={() => setKeyboardOpened(true)}
								/>
								<button
									className={cn('game-link', 'send-btn')}
									disabled={!email.length || isLoading}
									onClick={() => generatePDF()}
								>
									{
										isLoading &&
										<div role="status">
											<svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
												<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
											</svg>
											<span className="sr-only">Loading...</span>
										</div>
									}
									<span>ОТПРАВИТЬ</span>
								</button>
							</>
							:
							<div className='text-center py-6 text-2xl'>
								Письмо успешно отправлено
							</div>
					}

				</div>
			</ModalWindow>

			<ModalWindow windowState={keyboardOpened} setModal={setKeyboardOpened} variant='keyboard'>
				<KeyBoard
					emailValue={email}
					setEmailValue={setEmail}
					keyboardOpened={keyboardOpened}
					setKeyboardOpened={setKeyboardOpened}
				/>
			</ModalWindow>
		</GameWrapper>
	);
};

export default FinanceQuizzes;
