import classNames from 'classnames/bind';
import styles from './FinanceQuizzes.module.scss';
import { NavLink } from 'react-router-dom';
import gifCard1 from '../../assets/images/FinanceQuizzes/mmf2023/gif/1.gif'
import gifCard2 from '../../assets/images/FinanceQuizzes/mmf2023/gif/2.gif'
import gifCard3 from '../../assets/images/FinanceQuizzes/mmf2023/gif/3.gif'
import gifCard4 from '../../assets/images/FinanceQuizzes/mmf2023/gif/4.gif'
import FinanceQuizzesWrapper from './FinanceQuizzesWrapper';

const cn = classNames.bind(styles);

const FinanceQuizzes = () => {

	return (
		<FinanceQuizzesWrapper>
			<div className='pt-4 mb-16 3xl:mb-0'>
				{/* <div className='grid grid-cols-1 2xl:grid-cols-2 2xl:mb-8 gap-12 pt-72 2xl:pt-12 img-bg'> */}
				<div className={cn('px-8 2xl:px-0 grid grid-cols-1 2xl:grid-cols-2 2xl:mb-8 gap-12 2xl:pt-12','img-bg')}>
					<NavLink to='1'>
						<div className={cn('card-inner')}>
							<span>1</span>
							<div className={cn('card')}>
								<img src={gifCard1} alt="" />
								<div className={cn('choose')}>Выбрать</div>
							</div>
						</div>
					</NavLink>
					<NavLink to='2'>
						<div className={cn('card-inner')}>
							<span>2</span>
							<div className={cn('card')}>
								<img src={gifCard2} alt="" />
								<div className={cn('choose')}>Выбрать</div>
							</div>
						</div>
					</NavLink>
					<NavLink to='3'>
						<div className={cn('card-inner')}>
							<span>3</span>
							<div className={cn('card')}>
								<img src={gifCard3} alt="" />
								<div className={cn('choose')}>Выбрать</div>
							</div>
						</div>
					</NavLink>
					<NavLink to='4'>
						<div className={cn('card-inner')}>
							<span>4</span>
							<div className={cn('card')}>
								<img src={gifCard4} alt="" />
								<div className={cn('choose')}>Выбрать</div>
							</div>
						</div>
					</NavLink>
				</div>
			</div>

			{/* <img src={leftText} alt="" className={cn('img-position')} /> */}
		</FinanceQuizzesWrapper>
	);
};

export default FinanceQuizzes;
