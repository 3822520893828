import { useEffect, useState, useContext } from 'react';
import classNames from 'classnames/bind';
import styles from './Game.module.scss';
import { NavLink, useMatch } from 'react-router-dom';
import GameWrapper from './GameWrapper';
import { questions } from '../../data/gameData';
import backBtn from '../../assets/images/Game/back-btn.svg'
import nextArrow from '../../assets/images/Game/next-arrow.svg'
import points from '../../assets/images/Game/points-decor.svg'
import right from '../../assets/images/Game/right-icon.svg'
import wrong from '../../assets/images/Game/wrong-icon.svg'
import pigPhrase from '../../assets/images/Game/pig-phrase.svg'
import { UserScoreContext } from '../../context/ScoreContext';

const cn = classNames.bind(styles);

const GameQuestion = () => {
	const [question, setQuestion] = useState(null)
	const [answerItem, setAnswerItem] = useState(null)
	const { params: { questionNumber } } = useMatch('game/:questionNumber');

	const { increaseScore, addAnswer, userAnswers } = useContext(UserScoreContext);

	useEffect(() => {
		const [current] = questions.filter((el) => el.id === +questionNumber)
		setQuestion(current)
		setAnswerItem(null)

		if ((questionNumber in userAnswers) && userAnswers[questionNumber]) {
			const [currentQuestion] = questions.filter((el) => el.id === +questionNumber)
			currentQuestion.answers.forEach((el) => {
				if (el.isRight) setAnswerItem(el)
			})
		}
		if ((questionNumber in userAnswers) && !userAnswers[questionNumber]) {
			const [currentQuestion] = questions.filter((el) => el.id === +questionNumber)
			currentQuestion.answers.forEach((el) => {
				if (!el.isRight) setAnswerItem(el)
			})
		}
	}, [questionNumber])

	const setAnswer = (answer) => {
		if (!answerItem) {
			setAnswerItem(answer)
			addAnswer(questionNumber, answer.isRight)
			if (answer.isRight) increaseScore(1)
		}
	}

	return (
		<>
			{question &&
				<GameWrapper>
					<div className={cn('px-4 3xl:px-0')}>
						<div className='flex mt-6 mb-6  justify-center md:justify-start'>
							<span className={cn('question-number')}>Вопрос {questionNumber}</span>
						</div>
						<div className="flex flex-col md:flex-row items-center justify-between md:mt-2">
							<p className={cn('question-text','text-center md:text-left mb-6 md:mb-0 md:mr-4')}>
								{question.title}
							</p>
							<div className='flex items-center'>
								{
									+questionNumber > 1
										?
										<NavLink
											to={`/game/${ +questionNumber - 1 }`}
											className='mr-4'
										>
											<img src={backBtn} alt="" className={cn('btn-pre')} />
										</NavLink>
										:
										<img src={backBtn} alt="" className={cn('mr-4 opacity-50','btn-pre')} />
								}
								{
									answerItem
										?
										<NavLink
											to={(+questionNumber > 8) ? '/game-result' : `/game/${+questionNumber + 1}`}
											className={cn('next-btn')}
										>
											ДАЛЕЕ <img src={nextArrow} alt="" className='ml-3' />
										</NavLink>
										:
										<span className={cn('next-btn', 'opacity-50')}>
											ДАЛЕЕ <img src={nextArrow} alt="" className='ml-3' />
										</span>
								}

							</div>
						</div>

						<div className='grid grid-cols-1 md:grid-cols-2 gap-12 3xl:gap-24 mt-8 relative'>
							{
								question.answers.map((answer, index) =>
									<div
										key={index}
										className={cn('image-wrapper')}
										onClick={() => setAnswer(answer)}
									>
										<img className={cn('answer-image')} src={answer.image} alt="" />
										{/* Реплика морской свинки */}
										{(+questionNumber === 5 && index === 1 && answerItem)
											&& <img src={pigPhrase} alt="" className={cn('pig-phrase')} />}
										{
											answerItem && <div className={cn('answer-text')}>{answer.text}</div>
										}
										{
											(answerItem && answerItem.isRight && answerItem.text === answer.text) &&
											<div className={cn('right-answer')}>
												<img src={right} alt="" className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2' />
											</div>
										}
										{
											(answerItem && !answerItem.isRight && answerItem.text === answer.text) &&
											<div className={cn('wrong-answer')}>
												<img src={wrong} alt="" className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2' />
											</div>
										}
									</div>
								)}

							<img src={points} alt="" className='hidden 3xl:block 3xl:absolute 3xl:top-36 3xl:-left-12' />
							<img src={points} alt="" className='hidden 3xl:block 3xl:absolute 3xl:top-96 3xl:-right-12' />
						</div>
					</div>
				</GameWrapper>
			}
		</>
	);
};

export default GameQuestion;
