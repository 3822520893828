import qr1 from '../assets/images/FinanceQuizzes/mmf2023/qr-1.svg';
import qr2 from '../assets/images/FinanceQuizzes/mmf2023/qr-2.svg';
import qr3 from '../assets/images/FinanceQuizzes/mmf2023/qr-3.svg';
import qr4 from '../assets/images/FinanceQuizzes/mmf2023/qr-4.svg';
import img1 from '../assets/images/FinanceQuizzes/mmf2023/1_question_img.svg';
import img2 from '../assets/images/FinanceQuizzes/mmf2023/2_question_img.svg';
import img3 from '../assets/images/FinanceQuizzes/mmf2023/3_question_img.svg';
import img4 from '../assets/images/FinanceQuizzes/mmf2023/4_question_img.svg';

export const quizzQuestions = [
	{
		id: 1,
		text: 'Посмотрите на картинку:\n сколько стоит клубника?',
		image: img1,
		qr: qr1,
	},
	{
		id: 2,
		text: 'Какие два слова мы загадали в ребусе?',
		image: img2,
		qr: qr2,
	},
	{
		id: 3,
		text: 'Решите ребус: какое\n слово мы загадали?',
		image: img3,
		qr: qr3,
	},
	{
		id: 4,
		text: 'Как поместить шары внутрь кругов, чтобы получить в сумме 30?',
		image: img4,
		qr: qr4,
	},
];
