import React, { useState } from 'react';
import { createContext } from 'react';

export const UserScoreContext = createContext();

const ScoreContext = ({ children }) => {
	const [totalScore, setTotalScore] = useState(0);
	const [userAnswers, setUserAnswers] = useState({});

	const increaseScore = (points) => setTotalScore((prev) => (prev += points));
	const clearScore = () => setTotalScore(0);

	const addAnswer = (num, val) => {
		setUserAnswers({ ...userAnswers, [num]: val })
	}

	const clearAnswers = () => {
		setUserAnswers({})
	}

	return (
		<>
			<UserScoreContext.Provider
				value={{
					totalScore,
					increaseScore,
					clearScore,
					addAnswer,
					userAnswers,
					clearAnswers,
				}}
			>
				{children}
			</UserScoreContext.Provider>
		</>
	);
};

export default ScoreContext;
