import classNames from 'classnames/bind';
import styles from './Game.module.scss';
import { NavLink } from 'react-router-dom';
import title from '../../assets/images/Game/title.svg'
import homeBtn from '../../assets/images/_UI/home-btn.png'
import returnBtn from '../../assets/images/_UI/return-btn.png'

const cn = classNames.bind(styles);

const GameWrapper = ({ children, isReturnActive = true, returnLink = '/main-page' }) => {

	return (
		<div className={cn('wrapper', 'flex', 'flex-col', 'items-center', 'relative','container')}>

			<div className='container flex flex-col 3xl:flex-row justify-center items-center relative w-full'>
				<img src={title} alt="" />
				<div className='my-4 2xl:my-0 flex items-center 2xl:absolute 2xl:top-1/2 2xl:right-6 3xl:-translate-y-1/2'>
					{isReturnActive
						?
						<NavLink to={returnLink}>
							<img src={returnBtn} alt="" className='cursor-pointer' />
						</NavLink>
						:
						<img src={returnBtn} alt="" className='cursor-pointer opacity-50' />
					}
					<NavLink to='/main-page'>
						<img src={homeBtn} alt="" className='ml-4 cursor-pointer' />
					</NavLink>
				</div>
			</div>

			{children}
		</div>
	);
};

export default GameWrapper;
