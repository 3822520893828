import React from 'react';
import classNames from 'classnames/bind';
import styles from './ModalWindow.module.scss';
import cross from '../../assets/images/_UI/modal-cross.svg'
import bigCross from '../../assets/images/_UI/big-modal-close.svg'
import next from '../../assets/images/_UI/big-modal-next.svg'

const cn = classNames.bind(styles);

const ModalWindow = ({
	children,
	windowState,
	setModal,
	stripColor,
	variant = 'little',
	imageModal,
	changeElement,
	currentIndex,
	keyboardOpened,
}) => {

	return (
		<div
			className={cn('window', {
				'active': windowState,
				'!hidden': (variant !== 'images' && imageModal) || (variant === 'little' && keyboardOpened)
			})}
		>
			<div
				className={cn('relative', {
					'window__content--little': variant === 'little',
					'window__content--practices': variant === 'practices',
					'window__content--projects': variant === 'projects',
					'window__content--images': variant === 'images',
					'window__content--keyboard': variant === 'keyboard',
				})}
				onClick={event => event.stopPropagation()}
			>
				<div
					className={cn({
						'close-btn--little': variant === 'little',
						'close-btn--big': variant !== 'little',
					})}
					onClick={() => setModal(false)}
				>
					<img className='select-none' src={variant === 'little' ? cross : bigCross} alt="" />
				</div>

				{
					['practices', 'projects', 'images'].includes(variant) &&
					<>
						<img
							src={next}
							alt=""
							className={cn('prev-btn', {
								'!top-full !left-0 lg:!top-1/3 lg:!left-4' : (variant === 'images'),
								'!top-1/2 !left-0 lg:!top-1/3 lg:!left-4': (variant === 'projects'),
							})}
							onClick={() => changeElement('prev')}
						/>
						<img
							src={next}
							alt=""
							className={cn('next-btn', {
								'!top-full !right-0 lg:!top-1/3 lg:!right-4': (variant === 'images'),
								'!top-1/2 right-0 lg:!top-1/3 lg:!right-4': (variant === 'projects'),
							})}
							onClick={() => changeElement('next')}
						/>
					</>
				}

				{children}

				{variant === 'images' &&
					<div className='absolute left-0 right-0 -bottom-12 flex items-center justify-center gap-x-4'>
						<span className={cn('image-circle', { '!opacity-100': currentIndex === 0 })} onClick={() => changeElement(0)} />
						<span className={cn('image-circle', { '!opacity-100': currentIndex === 1 })} onClick={() => changeElement(1)} />
						<span className={cn('image-circle', { '!opacity-100': currentIndex === 2 })} onClick={() => changeElement(2)} />
						<span className={cn('image-circle', { '!opacity-100': currentIndex === 3 })} onClick={() => changeElement(3)} />
					</div>
				}

				{stripColor && <div className={cn('bottom-strip', {
					'bottom-strip-projects': variant === 'projects',
				})} style={{ background: stripColor }} />}
			</div>
		</div>
	);
};

export default ModalWindow;
