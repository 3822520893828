import spb from '../assets/images/BestPractices/emblems/spb.svg';
import volgograd from '../assets/images/BestPractices/emblems/volgograd.svg';
import rostov from '../assets/images/BestPractices/emblems/rostov.svg';
import yamal from '../assets/images/BestPractices/emblems/yamal.svg';
import msk_field from '../assets/images/BestPractices/emblems/msk-field.svg';
import msk from '../assets/images/BestPractices/emblems/moscow.svg';
import krim from '../assets/images/BestPractices/emblems/krim.svg';
import belgorod from '../assets/images/BestPractices/emblems/belgorod.svg';
import voronezh from '../assets/images/BestPractices/emblems/voronezh.svg';
import hmao from '../assets/images/BestPractices/emblems/hmao.svg';
import omsk from '../assets/images/BestPractices/emblems/omsk.svg';
import altay from '../assets/images/BestPractices/emblems/altay.svg';
import samara from '../assets/images/BestPractices/emblems/samara.svg';
import primorsk from '../assets/images/BestPractices/emblems/primorsk.svg';
import kamchatka from '../assets/images/BestPractices/emblems/kamchatka.svg';
import pskov from '../assets/images/BestPractices/emblems/pskov.svg';
import krasnodar from '../assets/images/BestPractices/emblems/krasnodar.svg';
import chuvash from '../assets/images/BestPractices/emblems/chuvash.svg';
import krasnoyarsk from '../assets/images/BestPractices/emblems/krasnoyarsk.svg';
import magadan from '../assets/images/BestPractices/emblems/magadan.svg';
import tomsk from '../assets/images/BestPractices/emblems/tomsk.svg';
import kirov from '../assets/images/BestPractices/emblems/kirov.svg';
import sevastopol from '../assets/images/BestPractices/emblems/sevastopol.svg';
import orel from '../assets/images/BestPractices/emblems/orel.svg';

import qrSpb from '../assets/images/BestPractices/qr/spb.jpg';
import qrVolgograd from '../assets/images/BestPractices/qr/volgograd.jpg';
import qrYamal from '../assets/images/BestPractices/qr/yamal.jpg';
import qrRostov from '../assets/images/BestPractices/qr/rostov.jpg';
import qrKrim from '../assets/images/BestPractices/qr/krim.jpg';
import qrMskFild from '../assets/images/BestPractices/qr/mskFild.jpg';
import qrBelgorod from '../assets/images/BestPractices/qr/belgorod.jpg';
import qrVoronezh from '../assets/images/BestPractices/qr/voronezh.jpg';
import qrHmao from '../assets/images/BestPractices/qr/hmao.jpg';
import qrOmsk from '../assets/images/BestPractices/qr/omsk.jpg';
import qrAltay from '../assets/images/BestPractices/qr/altay.jpg';
import qrPskov from '../assets/images/BestPractices/qr/pskov.jpg';
import qrKamchatka from '../assets/images/BestPractices/qr/kamchatka.jpg';
import qrKrasnodar from '../assets/images/BestPractices/qr/krasnodar.jpg';
import qrMsk from '../assets/images/BestPractices/qr/msk.jpg';
import qrChuvash from '../assets/images/BestPractices/qr/chuvash.jpg';


import qrSevastopol from '../assets/images/BestPractices/qr/sevastopol.jpg';
import qrOrel from '../assets/images/BestPractices/qr/orel.jpg';
import qrTomsk from '../assets/images/BestPractices/qr/tomsk.jpg';
import qrKirov from '../assets/images/BestPractices/qr/kirov.jpg';
import qrKrasnoyarsk from '../assets/images/BestPractices/qr/krasnoyarsk.jpg';
import qrMagadan from '../assets/images/BestPractices/qr/magadan.jpg';
import qrSamara from '../assets/images/BestPractices/qr/samara.jpg';
import qrPrimorsk from '../assets/images/BestPractices/qr/primorsk.jpg';


export const bestPracticesLeft = [
	{
		id: 1,
		title: 'Просвещение детей и молодежи',
		titlePopUp: 'Просвещение детей и молодежи',
		items: [
			{
				title: 'Финансовая грамотность в проекте школьного инициативного бюджетирования «Твой бюджет в школах»',
				text: 'Комплекс мероприятий, направленных на вовлечение учащихся 9-11 классов в процесс разработки и реализации инициатив по развитию школьной инфраструктуры.',
				audience: 'Школьники',
				coverage: 'От 5000 чел./год и более',
				developer: 'Комитет финансов Санкт-Петербурга',
				field: 'Санкт-Петербург',
				minWidth: 'saint-petersburg',
				link: 'https://xn--80apaohbc3aw9e.xn--p1ai/project/regions/sankt-peterburg/practices/finansovaia-gramotnost-v-proekte-skolnogo-iniciativnogo-biudzetirovaniia-tvoi-biudzet-v-skolax/',
				image: spb,
				qr: qrSpb,
			},
			{
				title: 'Мюзикл «Когда финансы поют романсы»',
				text: 'В основе идеи мюзикла лежит сборник Ю.В. Бреховой «Финансовые приключения друзей в стране под названием ГДЕНЬГИ». Сюжет строится на случайных и непредвиденных стечениях обстоятельств при совершении героями финансовых операций.',
				audience: 'Школьники, студенты',
				coverage: 'От 5000 чел./год и выше',
				developer: 'Комитет финансов Волгоградской области',
				field: 'Волгоградская область',
				minWidth: 'Vvolgograd-region',
				link: 'https://xn--80apaohbc3aw9e.xn--p1ai/project/regions/volgogradskaya-oblast/practices/sozdanie-postanovka-i-prokat-miuzikla-kogda-finansy-poiut-romansy-libretto-brexova-iuliia/',
				image: volgograd,
				qr: qrVolgograd,
			},
			{
				title: 'Конкурс рисунков «Бюджет в картинках»',
				text: 'Дети от 6 до 10 лет рисуют картины по теме бюджетной грамотности. С целью выявления победителей конкурсные работы размещаются на официальных ресурсах ДФ ЯНАО для голосования жителей.',
				audience: 'Школьники',
				coverage: 'От 5000 чел./год и выше',
				developer: 'Департамент финансов Ямало-Ненецкого автономного округа',
				field: 'Ямало-Ненецкий\n автономный округ',
				minWidth:'yamal-nenets',
				link: 'https://xn--80apaohbc3aw9e.xn--p1ai/project/regions/yamalo-neneckii-avtonomnyi-okrug/practices/konkurs-risunkov-biudzet-v-kartinkax/',
				image: yamal,
				qr: qrYamal,
			},
			{
				title: 'Обучающая программа для школьников «Хочу бизнес»',
				text: 'Участники в игровой форме учатся строить бизнес. В качестве лекторов привлекаются министры профильных ведомств, специалисты институтов поддержки МСП, успешные бизнесмены.',
				audience: 'Школьники',
				coverage: 'От 100 до 1000 чел./год',
				developer: 'АНО микрофинансовая компания «Ростовское региональное агентство поддержки предпринимательства»',
				field: 'Ростовская\nобласть',
				minWidth: 'rostov-region',
				link: 'https://xn--80apaohbc3aw9e.xn--p1ai/project/regions/rostovskaya-oblast/practices/obucaiushhaia-programma-dlia-skolnikov-xocu-biznes/',
				image: rostov,
				qr: qrRostov,
			},
		],
	},
	{
		id: 2,
		title: 'Образовательные и просветительские\n проекты для взрослых',
		titlePopUp: 'Образовательные и просветительские проекты\n финансовой грамотности для взрослых',
		items: [
			{
				title: 'Партнерско-образовательный интенсив «Бизнес-прорыв»',
				text: 'Интенсив нацелен на финансовое просвещение физлиц, заинтересованных в предпринимательской деятельности, и действующих субъектов МСП.',
				audience: 'Взрослое население, представители СМП и самозанятые граждане',
				coverage: 'От 1000 до 5000 чел./год',
				developer: 'НО «Крымский государственный фонд поддержки предпринимательства»',
				field: 'Республика Крым',
				minWidth: 'crimea',
				link: 'https://xn--80apaohbc3aw9e.xn--p1ai/project/regions/krym/practices/partnersko-obrazovatelnyi-intensiv-biznes-proryv/',
				image: krim,
				qr: qrKrim,
			},
			{
				title: 'Курс по финансовой грамотности «Поддержка мобилизованных и их семей»',
				text: 'Практика нацелена на финансовое образование в сфере введенных льгот и форм поддержки для мобилизованных граждан и их семей.',
				audience: 'Взрослое население',
				coverage: 'До 100 чел./год',
				developer: 'ГБПОУ МО «Дмитровский техникум»',
				field: 'Московская область',
				link: 'https://xn--80apaohbc3aw9e.xn--p1ai/project/regions/moskovskaya-oblast/practices/kurs-po-finansovoi-gramotnosti-podderzka-mobilizovannyx-i-ix-semei/',
				image: msk_field,
				qr: qrMskFild,
			},
			{
				title: 'Региональный конкурс методических разработок, направленных на повышение финансовой грамотности у обучающихся',
				text: 'Конкурс нацелен на распространение эффективных образовательных практик, направленных на формирование у обучающихся знаний и навыков в области финансовой грамотности.',
				audience: 'Взрослое население',
				coverage: 'До 100 чел./год',
				developer: 'ОГАОУ ДПО «Белгородский институт развития образования», Отделение Банка России по Белгородской области',
				field: 'Белгородская область',
				link: 'https://xn--80apaohbc3aw9e.xn--p1ai/project/regions/belgorodskaya-oblast/practices/regionalnyi-konkurs-metodiceskix-razrabotok-napravlennyx-na-povysenie-finansovoi-gramotnosti-obucaiushhixsia/',
				image: belgorod,
				qr: qrBelgorod,
			},
			{
				title: 'Проект «ФинГрамЛайф»',
				text: 'Проведении обучающих и историко-просветительских мероприятий в смешанном формате (очно/онлайн) для различных целевых аудиторий.',
				audience: 'Школьники, студенты, взрослое население',
				coverage: 'От 100 до 1000 чел./год',
				developer: 'Отделение по Воронежской области ГУ Банка России по ЦФО',
				field: 'Воронежская область',
				link: 'https://xn--80apaohbc3aw9e.xn--p1ai/project/regions/voronezskaya-oblast/practices/obrazovatelnyi-proekt-fingramlaif/',
				image: voronezh,
				qr: qrVoronezh,
			},
		],
	},
	{
		id: 3,
		title: 'Специальные проекты с органами\n соцзащиты, СФР, ФНС, и др.',
		titlePopUp: 'Специальные проекты с органами соцзащиты,\n МФЦ, СФР, ФНС, Роспотребнадзором и др.',
		items: [
			{
				title: 'Финансовое просвещение взрослого населения с использованием потенциала Центров занятости населения и МФЦ',
				text: 'Обучение сотрудников Центров занятости населения и МФЦ ХМАО — Югры, которые после обучения принимают участие в реализации мероприятий региональной программы.',
				audience: 'Студенты, взрослое население, пенсионеры и предпенсионеры, представители СМП и самозанятые граждане, люди с ОВЗ',
				coverage: 'От 5000 чел./год и выше',
				developer: 'РЦФГ ХМАО-Югра, Отделение Банка России по Тюменской области, Правительство ХМАО — Югры',
				field: 'Ханты-Мансийский автономный округ — Югра',
				minWidth: 'yugra',
				link: 'https://xn--80apaohbc3aw9e.xn--p1ai/project/regions/xanty-mansiiskii-avtonomnyi-okrug/practices/praktika-napravlena-na-formirovanie-licnostnyx-i-professionalnyx-kompetencii-dlia-povyseniia-urovnia-finansovoi-gramotnosti-u-predstavitelei-centrov-zaniatosti-naseleniia-i-mfc-xanty-mansiiskogo-avtonomnogo-okruga-iugry/',
				image: hmao,
				qr: qrHmao,
			},
			{
				title: '«Неделя единых действий»',
				text: 'Проведение сотрудниками КЦСОН и социально-реабилитационных центров в течение недели игр по финансовой грамотности для детей, посещающих данный орган социальной защиты.',
				audience: 'Дошкольники, школьники',
				coverage: 'От 1000 до 5000 чел./год',
				developer: 'Отделение Банка России по Омской области, Министерство труда и социального развития Омской области',
				field: 'Омская область',
				link: 'https://xn--80apaohbc3aw9e.xn--p1ai/project/regions/omskaya-oblast/practices/sotrudnikami-otdeleniia-omsk-podgotovleno-4-scenariia-finansovyx-igr-dlia-detei-razlicnyx-vozrastov-doskolniki-nacalnaia-skola-sredniaia-skola-starsaia-skola/',
				image: omsk,
				qr: qrOmsk,
			},
		],
	},
	{
		id: 4,
		title: 'Специальные проекты для\n людей старшего возраста',
		titlePopUp: 'Специальные проекты для людей старшего\n возраста',
		items: [
			{
				title: 'Проект «Финансовая забота о старших»',
				text: 'Серия информационно-просветительских мероприятий в муниципальных образованиях края, приуроченных к месячнику пожилого человека и нацеленных на повышение уровня финансовой грамотности.',
				audience: 'Пенсионеры и предпенсионеры',
				coverage: 'От 100 до 1000 чел./год',
				developer: 'РЦФГ Алтайского края, Министерство финансов Алтайского края',
				field: 'Алтайский край',
				link: 'https://xn--80apaohbc3aw9e.xn--p1ai/project/regions/omskaya-oblast/practices/sotrudnikami-otdeleniia-omsk-podgotovleno-4-scenariia-finansovyx-igr-dlia-detei-razlicnyx-vozrastov-doskolniki-nacalnaia-skola-sredniaia-skola-starsaia-skola/',
				image: altay,
				qr: qrAltay,
			},
			{
				title: 'Проект ФинЗОЖ — Цифровой мир и финграмотность',
				text: 'Проект направлен на адаптацию людей старшего поколения к полноценной жизни в условиях цифровой реальности.',
				audience: 'Пенсионеры и предпенсионеры',
				coverage: 'От 100 до 1000 чел./год',
				developer: 'Общероссийская общественная организация «Союз пенсионеров России» по Псковской области',
				field: 'Псковская область',
				minWidth: 'pskov',
				link: 'https://xn--80apaohbc3aw9e.xn--p1ai/project/regions/pskovskaya-oblast/practices/proekt-napravlen-na-adaptaciiu-liudei-starsego-pokoleniia-k-polnocennoi-zizni-v-usloviiax-cifrovoi-realnosti/',
				image: pskov,
				qr:qrPskov,
			},
		],
	},
]

export const bestPracticesCenter = [
	{
		id: 5,
		title: 'Информационная кампания',
		titlePopUp: 'Информационная кампания по финансовой\n грамотности',
		items: [
			{
				title: 'Поезд финансовой грамотности',
				text: 'Тематический поезд метро, в вагонах которого размещены иллюстрации с советами и ответами на популярные вопросы о личных финансах. Материалы сопровождаются QR-кодами со ссылками на полезные официальные ресурсы и статьи.',
				audience: 'Школьники, студенты, взрослое население, пенсионеры и предпенсионеры, представители СМП и самозанятые граждане, люди с ОВЗ',
				coverage: 'От 5000 чел./год и выше',
				developer: 'Департамент финансов города Москвы, Главное управление Банка России по ЦФО',
				field: 'Москва',
				link: 'https://xn--80apaohbc3aw9e.xn--p1ai/project/regions/moskva/practices/poezd-finansovoi-gramotnosti/',
				image: msk,
				qr: qrMsk,
			},
			{
				title: 'Мультсериал «Бюджет простыми словами»',
				text: 'В мультсериале доступным языком рассказывается об основных принципах формирования бюджета, доходах, расходных обязательствах, мероприятиях по повышению финансовой грамотности и пр.',
				audience: 'Дошкольники, школьники, студенты, взрослое население, пенсионеры и предпенсионеры, представители СМП и самозанятые граждане, люди с ОВЗ',
				coverage: 'От 5000 чел./год и выше',
				developer: 'КГАУ «Информационное агентство «КАМЧАТКА»',
				field: 'Камчатский край',
				link: 'https://xn--80apaohbc3aw9e.xn--p1ai/project/regions/kamcatskii-krai/practices/multserial-biudzet-prostymi-slovami/',
				image: kamchatka,
				qr: qrKamchatka,
			},
			{
				title: 'Островки финансовой грамотности',
				text: 'Размещение на популярных остановках общественного транспорта ненавязчивой социальной рекламы, информирующей граждан о различных аспектах финансовой грамотности.',
				audience: 'Дошкольники, школьники, студенты, взрослое население, пенсионеры, представители СМП и самозанятые граждане, люди с ОВЗ',
				coverage: 'От 5000 чел./год и выше',
				developer: 'РЦФГ Чувашский Республики, территориальное подразделение Газпромбанка, администрация г. Новочебоксарска',
				field: 'Чувашская Республика — Чувашия',
				fieldSmall: 'Чувашия',
				minWidth: 'chuvashia',
				link: 'https://xn--80apaohbc3aw9e.xn--p1ai/project/regions/cuvasskaya-respublika/practices/ostrovki-finansovoi-gramotnosti/',
				image: chuvash,
				qr: qrChuvash,
			},
			{
				title: 'Изготовление и размещение информационных баннеров по финансовой и налоговой грамотности',
				text: 'Размещение тематической социальной наружной рекламы, информирующей о наиболее острых проблемах финансовой и налоговой грамотности.',
				audience: 'Взрослое население',
				coverage: 'От 5000 чел./год и выше',
				developer: 'Министерство экономики Краснодарского края',
				field: 'Краснодарский край',
				link: 'https://xn--80apaohbc3aw9e.xn--p1ai/project/regions/krasnodarskii-krai/practices/izgotovlenie-i-razmeshhenie-informacionnyx-bannerov-po-finansovoi-i-nalogovoi-gramotnosti/',
				image: krasnodar,
				qr: qrKrasnodar,
			},
		],
	},
]

export const bestPracticesRight = [
	{
		id: 6,
		title: 'Волонтерские проекты',
		titlePopUp: 'Волонтерские проекты',
		items: [
			{
				title: 'Школа волонтеров финансовой грамотности',
				text: 'Проведение студентами просветительских акций для людей пенсионного и предпенсионного возраста с целью предотвращения финансового мошенничества (личные встречи, размещение видеороликов в общественном транспорте и госучреждениях).',
				audience: 'Студенты, пенсионеры и предпенсионеры',
				coverage: 'От 100 до 1000 чел./год',
				developer: 'Севастопольский филиал РЭУ им. Г.В. Плеханова',
				field: 'Севастополь',
				link: 'https://xn--80apaohbc3aw9e.xn--p1ai/project/regions/sevastopol/practices/skola-volonterov-finansovoi-gramotnosti/',
				image: sevastopol,
				qr: qrSevastopol,
			},
			{
				title: 'Networking-марафон начинающих предпринимателей «Новые возможности»',
				text: 'Марафон для студентов состоит из мероприятий различного формата, которые посвящены финансовой и цифровой грамотности начинающего предпринимателя, вопросам создания бизнеса, налогообложения.',
				audience: 'Студенты',
				coverage: 'От 100 до 1100 чел./год',
				developer: 'ФГБОУ ВО «Орловский государственный университет им. И.С. Тургенева» (РЦФГ), АРФГ, Росмолодежь',
				field: 'Орловская область',
				minWidth: 'orel',
				link: 'https://xn--80apaohbc3aw9e.xn--p1ai/project/regions/orlovskaya-oblast/practices/networking-marafon-nacinaiushhix-predprinimatelei-novye-vozmoznosti/',
				image: orel,
				qr: qrOrel,
			},
		],
	},
	{
		id: 7,
		title: 'Цифровые продукты',
		titlePopUp: 'Цифровые продукты по финансовой грамотности',
		items: [
			{
				title: 'Ежегодная межрегиональная премия «Финансовый престиж»',
				text: 'Единственная в России межрегиональная награда, вручаемая финансовым организациям по результатам народного голосования, контрольных проверок и независимых оценок Экспертного совета.',
				audience: 'Студенты, взрослое население, пенсионеры и предпенсионеры, представители СМП и самозанятые граждане, люди с ОВЗ',
				coverage: 'От 1000 до 5000 чел./год',
				developer: 'Департамент финансов Томской области, РЦФГ Томской области',
				field: 'Томская область',
				link: 'https://xn--80apaohbc3aw9e.xn--p1ai/project/regions/tomskaya-oblast/practices/ezegodnaia-mezregionalnaia-premiia-finansovyi-prestiz/',
				image: tomsk,
				qr: qrTomsk,
			},
			{
				title: 'Онлайн-курс «Основы финансовой грамотности»',
				text: 'Курс подготовлены в соответствии с единой рамкой компетенций по финансовой грамотности и нацелен на подготовку студентов, старшеклассников и педагогов, слушателей программ профессиональной переподготовки и повышения квалификации.',
				audience: 'Школьники, студенты, взрослое население, представители СМП и самозанятые граждане, люди с ОВЗ',
				coverage: 'От 1000 до 5000 человек/год',
				developer: 'ФГБОУ ВО «Вятский государственный университет»',
				field: 'Кировская область',
				minWidth: 'kirov',
				link: 'https://xn--80apaohbc3aw9e.xn--p1ai/project/regions/kirovskaya-oblast/practices/onlain-kurs-osnovy-finansovoi-gramotnosti-dlia-sirokoi-auditorii/',
				image: kirov,
				qr: qrKirov,
			},
		],
	},
	{
		id: 8,
		title: 'Специальные проекты со СМИ, блогерами и в социальных сетях',
		titlePopUp: 'Специальные проекты со СМИ, блогерами и в\n социальных сетях',
		items: [
			{
				title: 'Поезд Программы поддержки местных инициатив',
				text: 'Создание и поддержка информационного сопровождения всех этапов программы поддержки местных инициатив через медиакампанию в стиле путешествия на поезде. Весь цикл от подачи заявок до реализации инициативных проектов проходит под эгидой поезда.',
				audience: 'Школьники, студенты, взрослое население, пенсионеры и предпенсионеры',
				coverage: 'От 1000 до 5000 чел./год',
				developer: 'Институт государственного и муниципального управления при Правительстве Красноярского края',
				field: 'Красноярский край',
				minWidth: 'krasnoyarsk',
				link: 'https://xn--80apaohbc3aw9e.xn--p1ai/project/regions/krasnoyarskii-krai/practices/proekt-zakliucaetsia-v-sozdanii-i-podderzke-informacionnogo-soprovozdeniia-vsex-etapov-programmy-podderzki-mestnyx-iniciativ-cerez-mediakampaniiu-v-stile-putesestviia-na-poezde/',
				image: krasnoyarsk,
				qr: qrKrasnoyarsk,
			},
			{
				title: 'Еженедельная рубрика на ТВ «Простая экономика»',
				text: 'Еженедельная утренняя передача на областном телеканале «Колыма-плюс», в которой есть ряд тематических блоков: денежно-кредитная политика Банка России и инфляция в регионе; данные о состоянии отдельных сегментов экономического рынка области; финсловарь.',
				audience: 'Студенты, взрослое население, пенсионеры и предпенсионеры, представители СМП и самозанятые граждане',
				coverage: 'От 5000 чел./год и выше',
				developer: 'Отделение Магадан Дальневосточного ГУ Центрального банка Российской Федерации, ООО «ТВ-Колыма-плюс»',
				field: 'Магаданская область',
				minWidth: 'magadan',
				link: 'https://xn--80apaohbc3aw9e.xn--p1ai/project/regions/magadanskaya-oblast/practices/ezenedelnaia-peredaca-na-oblastnom-telekanale-kolyma-plius-vypuski-vyxodiat-v-utrennem-sou-v-0630-0730-0830/',
				image: magadan,
				qr: qrMagadan,
			},
		],
	},
	{
		id: 9,
		title: 'Специальные проекты для людей с ОВЗ',
		titlePopUp: 'Специальные проекты для людей с ОВЗ',
		items: [
			{
				title: 'Квест-игра «Территория финансов» для особенных детей',
				text: 'Игра помогает особенным детям формировать и закреплять знания по финансовой грамотности.',
				audience: 'Дети с ОВЗ',
				coverage: 'До 100 чел./год',
				developer: 'Отделение по Самарской области Волго-Вятского ГУ Центрального банка Российской Федерации',
				field: 'Самарская область',
				minWidth: 'samara',
				link: 'https://xn--80apaohbc3aw9e.xn--p1ai/project/regions/samarskaya-oblast/practices/territoriia-finansov-dlia-osobennyx-detei/',
				image: samara,
				qr: qrSamara,
			},
			{
				title: 'Проект по финансовому просвещению людей с нарушениями слуха EduSurd',
				text: 'Организация различных мероприятий по повышению финансовой грамотности для лиц с нарушениями слуха силами команды сертифицированных волонтеров — преподавателей и студентов ДВФУ',
				audience: 'Люди с ОВЗ',
				coverage: 'От 1000 до 5000 чел./год',
				developer: 'Центр компетенций по финансовой грамотности Дальневосточного федерального университета',
				field: 'Приморский край',
				minWidth: 'primorsk',
				link: 'https://xn--80apaohbc3aw9e.xn--p1ai/project/regions/primorskii-krai/practices/proekt-po-finansovomu-prosveshheniiu-liudei-s-naruseniiami-sluxa-edusurd/',
				image: primorsk,
				qr: qrPrimorsk,
			},
		],
	},
]
