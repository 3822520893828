import { useLayoutEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './FinanceQuizzes.module.scss';
import { NavLink, useMatch } from 'react-router-dom';
import FinanceQuizzesWrapper from './FinanceQuizzesWrapper';
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import loader from '../../assets/images/_UI/loader.svg'
import { quizzQuestions } from '../../data/financeQuizzes';

const cn = classNames.bind(styles);

const FinanceQuizzes = () => {
	const [isModalOpened, setIsModalOpened] = useState(false);
	const [question, setQuestion] = useState(null);
	const { params: { quizzNumber } } = useMatch('finance-quizzes/:quizzNumber');

	useLayoutEffect(() => {
		const [currentQuestion] = quizzQuestions.filter((el) => el.id === +quizzNumber)
		setQuestion(currentQuestion)
	}, [quizzNumber])

	return (
		<>
			{question &&
				<>
				<FinanceQuizzesWrapper returnLink='/finance-quizzes'>
					<div className='container w-full'>
						<div className={cn('page-grid-template','page-bg')}>
							{/* <div className={cn('container m-auto')}> */}
								<div className={cn('page-title','flex flex-col')}>
									<div className={cn('quizz-number')}>
										Загадка {quizzNumber}
									</div>
									<p className={cn('quizz-text')}>
										{question.text}
									</p>
								</div>
								<div className={cn('page-image')}>
									<img src={question.image} alt="" />
								</div>
								<div className={cn('page-body')}>
									<div className={cn('btn-group', 'flex flex-col mb-8 2xl:flex-row 2xl:mr-0 items-center pt-14')}>
										<button
											className={cn('btn', 'btn-red')}
											onClick={() => setIsModalOpened(true)}
										>
											Сдаюсь. Скажите правильный ответ!
										</button>
										<button
											className={cn('btn', 'btn-green')}
											onClick={() => setIsModalOpened(true)}
										>
											<span className='whitespace-nowrap'>Я знаю ответ! </span>
											<span>Готов проверить себя!</span>
										</button>
									</div>
								</div>
						</div>

					</div>
					</FinanceQuizzesWrapper>

					<ModalWindow windowState={isModalOpened} setModal={setIsModalOpened} stripColor='#EF7D00'>
						<div className=''>
							<div className='w-5/6 mx-auto relative'>
								<img src={loader} alt="" className='w-full opacity-40' />
								<img src={question.qr} alt="" className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2' />
							</div>
							<p className={cn('modal-text')}>
								Чтобы узнать правильный ответ, <br /> наведите камеру телефона <br /> на QR-код
							</p>
							<div className='flex justify-center pt-8 pb-12'>
								<NavLink
									to='/finance-quizzes'
									className={cn('modal-link')}
								>
									другие загадки
								</NavLink>
							</div>
						</div>
					</ModalWindow>
				</>
			}
		</>
	);
};

export default FinanceQuizzes;
