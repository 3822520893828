import { useEffect } from 'react';
import './App.scss';
import { Route, Routes } from 'react-router-dom';
import MainPage from "./pages/MainPage/MainPage";
import BestPractices from "./pages/BestPractices/BestPractices";
import FinanceQuizzes from "./pages/FinanceQuizzes/FinanceQuizzes";
import FinanceQuizzQuestion from "./pages/FinanceQuizzes/FinanceQuizzQuestion";
import BestProjects from "./pages/BestProjects/BestProjects";
import SplashScreen from './pages/SplashScreen/SplashScreen';
import GameTitle from './pages/Game/GameTitle';
import GameQuestion from './pages/Game/GameQuestion';

const App = () => {

	useEffect(() => {
		document.addEventListener('contextmenu', event => event.preventDefault());
		if (window.innerWidth === 1920) {
			document.addEventListener('touchmove', (e) => {
				e.preventDefault();
			}, { passive: false });
		}
		return () => {
			document.removeEventListener('contextmenu', event => event.preventDefault());
			if (window.innerWidth === 1920) {
				document.removeEventListener('touchmove', (e) => {
					e.preventDefault();
				}, { passive: false });
			}
		}
	}, [])


	return (
		<div className="app-wrapper">
			<Routes>
				<Route path="/" element={<SplashScreen />} />
				<Route path="/game" element={<GameTitle />} />
				<Route path="/game-result" element={<GameTitle />} />
				<Route path="/game/:questionNumber" element={<GameQuestion />} />
				<Route path="/main-page" element={<MainPage />} />
				<Route path="/best-practices" element={<BestPractices />} />
				<Route path="/best-projects" element={<BestProjects />} />
				<Route path="/finance-quizzes" element={<FinanceQuizzes />} />
				<Route path="/finance-quizzes/:quizzNumber" element={<FinanceQuizzQuestion />} />
			</Routes>
		</div>
	);
};

export default App;
