import { useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import styles from './MainPage.module.scss';
import { cardsData } from "../../data/mainPage";
import loader from '../../assets/images/_UI/loader.svg'
import myFinanses from '../../assets/images/_UI/my-finanses.svg'
import { NavLink } from 'react-router-dom';
import { gsap } from 'gsap'
import RegionsMap from '../../components/RegionsMap'
import { useMainPageAnimation } from '../../hooks/useAnimation';
import {useNavigate} from 'react-router-dom';


const cn = classNames.bind(styles);

const MainPage = () => {

	const navigate = useNavigate();

	const finances = useRef(null)
	const strips = useRef(null)
	const str = gsap.utils.selector(strips)
	const cards = useRef(null)
	const crds = gsap.utils.selector(cards)
	const map = useRef(null)

	// useEffect(() => {
	// 	const timeout = setTimeout(() => {
	// 		navigate('/')
	// 	}, 40000);

	// 	return () => {
	// 		clearTimeout(timeout)
	// 	}
	// }, [])

	useMainPageAnimation(map, crds, str, finances)

	return (
		<div className='bg-white w-screen h-screen'>
			<div className='w-full h-full p-8 relative'>
				<div className='flex justify-end absolute top-10 left-10 right-10 z-10'>
					<NavLink to='/' className={cn('btn', 'flex', 'items-center', 'justify-center')}>
						<img src={loader} alt="" />
					</NavLink>
				</div>
				<div className="main-page-logo w-3/4 lg:w-auto absolute top-36 left-1/2 -translate-x-1/2 z-10">
					<div className="main-page-strips flex justify-center flex-col">

						<ul className={cn('markers-list', 'mx-auto', 'justify-center', 'mb-3')} ref={strips}>
							<li className={cn('rounded-l-full','bg-theme-1')}></li>
							<li className={cn('bg-theme-6')}></li>
							<li className={cn('bg-theme-2')}></li>
							<li className={cn('bg-theme-3')}></li>
							<li className={cn('bg-theme-5')}></li>
							<li className={cn('rounded-r-full','bg-theme-7')}></li>
						</ul>

						{/* <div className='flex items-center mb-8 scale-50 lg:scale-100' ref={strips} >
							<img className='mr-1' src={strip_1} alt="" />
							<img className='mr-1' src={strip_2} alt="" />
							<img className='mr-1' src={strip_3} alt="" />
							<img className='mr-1' src={strip_4} alt="" />
							<img className='mr-1' src={strip_5} alt="" />
							<img src={strip_6} alt="" />
						</div> */}

						<div className='h-24 overflow-hidden'>
							<img src={myFinanses} alt="" ref={finances} className='m-auto'/>
							{/* Мои финансы */}
						</div>
					</div>
				</div>
				<div
					className={cn('main-page-cards', 'w-11/12','lg:w-auto','grid', 'grid-cols-1', '2xl:grid-cols-2', 'gap-12', 'w-fit1', 'absolute', 'z-10')}
					ref={cards}
				>
					{cardsData.map((el, index) =>
						<NavLink
							key={el.url}
							to={el.url}
							className={cn('main-page-card')}
							style={{ background: el.gradient }}
						>
							<p className={cn('card-text')}>{el.text}</p>
							<div className={cn('main-page-card-circle', 'relative')}>
								<img className={cn('absolute', {
									'circle-img': index === 2 || index === 3,
									'circle-text': index === 0 || index === 1,
								})} src={el.decor} alt="" />
							</div>
						</NavLink>
					)}
				</div>
				<div className='top-0 bottom-0 left-0 right-0 absolute z-0 pt-8'>
					<RegionsMap ref={map} />
				</div>
			</div>
		</div>
	);
};

export default MainPage;
