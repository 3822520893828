import { useEffect, useState, useMemo } from 'react';
import classNames from 'classnames/bind';
import styles from './BestPractices.module.scss';
import title from '../../assets/images/BestPractices/title.svg'
import BgWrapper from './BgWrapper';
import { bestPracticesLeft, bestPracticesRight, bestPracticesCenter } from '../../data/bestPractices'
import CircularSlider from '@fseehawer/react-circular-slider';
import loader from '../../assets/images/_UI/loader.svg'
import qr from '../../assets/images/BestPractices/qr.svg'
import ModalWindow from '../../components/ModalWindow/ModalWindow';

const cn = classNames.bind(styles);

const BestPractices = () => {
	const [isModalOpened, setIsModalOpened] = useState(false)
	const [currentPracticesArray, setCurrentPracticesArray] = useState(null)
	const [currentPractice, setCurrentPractice] = useState(null)
	const [currentItemIndex, setCurrentItemIndex] = useState(0)
	const [knobPosition, setKnobPosition] = useState(0)

	useEffect(() => {
		if (!isModalOpened) {
			setCurrentPracticesArray(null)
			setCurrentPractice(null)
			setCurrentItemIndex(0)
		}
	}, [isModalOpened])

	useEffect(() => {
		const [path] = document.getElementsByTagName("path");
		path.remove()

		const rects = document.getElementsByTagName("rect");
		if (rects.length) {
			[...rects].forEach((el) => {
				el.remove()
			})
		}

		const circles = document.getElementsByTagName("circle");
		if (circles.length) {
			const [first] = circles;
			first.setAttribute("stroke-dasharray", "0.5em");
			first.setAttribute("stroke", "#ABB2B6");
			first.setAttribute("id", "circle");
		}

		const interval = setInterval(() => {
			setKnobPosition((prev) => {
				if (prev <= 359) return prev + 1
				return 0
			})
		}, 50);

		return () => {
			clearInterval(interval)
		}
	}, [])

	useEffect(() => {
		if (!isModalOpened && currentPracticesArray) {
			setIsModalOpened(true)
			setCurrentPractice(currentPracticesArray.items[0])
		}
		if (isModalOpened && currentPracticesArray) {
			setCurrentPractice(currentPracticesArray.items[0])
		}
	}, [currentPracticesArray])

	const knobColor = useMemo(() => {
		if ((knobPosition >= 0 && knobPosition <= 44) || (knobPosition >= 336 && knobPosition <= 360)) return '#AAB2B6'
		if (knobPosition > 44 && knobPosition <= 74) return '#27BDBE'
		if (knobPosition > 74 && knobPosition <= 108) return '#005BA9'
		if (knobPosition > 108 && knobPosition <= 144) return '#EE2D62'
		if (knobPosition > 144 && knobPosition <= 180) return '#F58220'
		if (knobPosition > 180 && knobPosition <= 335) return '#04A564'
	}, [knobPosition])

	const openPractice = (el) => {
		setCurrentPracticesArray(el)
		setCurrentItemIndex(el.id)
	}

	const changePractice = (val) => {
		const practices = [...bestPracticesLeft, ...bestPracticesCenter, ...bestPracticesRight]
		if (val === 'prev' && currentItemIndex > 1) {
			const [prevPractice] = practices.filter((el) => el.id === (currentItemIndex - 1))
			setCurrentPracticesArray(prevPractice)
			setCurrentItemIndex(prevPractice.id)
		}
		if (val === 'prev' && currentItemIndex === 1) {
			const [prevPractice] = practices.filter((el) => el.id === 9)
			setCurrentPracticesArray(prevPractice)
			setCurrentItemIndex(prevPractice.id)
		}
		if (val === 'next' && currentItemIndex < 9) {
			const [nextPractice] = practices.filter((el) => el.id === (currentItemIndex + 1))
			setCurrentPracticesArray(nextPractice)
			setCurrentItemIndex(nextPractice.id)
		}
		if (val === 'next' && currentItemIndex === 9) {
			const [nextPractice] = practices.filter((el) => el.id === 1)
			setCurrentPracticesArray(nextPractice)
			setCurrentItemIndex(nextPractice.id)
		}
	}



	return (
		<BgWrapper title={title} isReturnActive={true}>
			<div className='container'>
				<div className='grid grid-cols-1 xl:grid-cols-3 w-full flex-auto'>
					<ul className='flex items-start order-3 flex-col-reverse xl:order-none xl:flex-col xl:items-end'>
						{
							bestPracticesLeft.map((el, index) =>
								<li
									key={el.id}
									className={cn('practice-item', `practice-item-mr-${index}`, {
										'practice-item--green': (index === 0 && (knobPosition >= 297 && knobPosition <= 318)) || (index === 1 && (knobPosition >= 272 && knobPosition < 297)) || (index === 2 && (knobPosition >= 243 && knobPosition < 272)) || (index === 3 && (knobPosition >= 218 && knobPosition < 243)),
									})}
									onClick={() => openPractice(el)}
								>
									{el.title}
								</li>
							)
						}
					</ul>
					<div className='flex items-start order-2 xl:order-none flex-col xl:items-center xl:justify-between'>
						<div className=' hidden  xl:flex items-center justify-center relative flex-auto'>
							<div className='hidden xl:block'>
								<CircularSlider
									width={560}
									hideLabelValue={true}
									knobColor={knobColor}
									progressColorFrom="#ABB2B6"
									progressColorTo="#ABB2B6"
									trackColor="#ABB2B6"
									progressSize={5}
									trackSize={3}
									dataIndex={knobPosition}
									knobDraggable={false}
								// onChange={value => { setKnobPosition(value) }}
								/>
							</div>
							<img src={loader} alt="" className={cn('loader-img','hidden xl:block')} />
							<img src={qr} alt="" className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-2/3' />
							<p className={cn('round-text', 'mt-4')}>Еще больше <br /> региональных практик <br /> на <span>моифинансы.рф</span></p>
						</div>
						<ul className='w-full xl:mt-4 2xl:mt-20'>
							{
								bestPracticesCenter.map((el) =>
									<li
										key={el.id}
										className={cn('practice-item', {
											'practice-item--green': (knobPosition >= 181 && knobPosition < 218),
											'practice-item--orange': (knobPosition >= 145 && knobPosition < 181),
										})}
										onClick={() => openPractice(el)}
									>
										{el.title}
									</li>
								)
							}
						</ul>
					</div>
					<ul className='flex items-start order-1 xl:order-none flex-col'>
						{
							bestPracticesRight.map((el, index) =>
								<li
									key={el.id}
									className={cn('practice-item', `practice-item-ml-${index}`, {
										'practice-item--pink': (index === 3 && (knobPosition >= 109 && knobPosition < 145)),
										'practice-item--blue': ((index === 2 && (knobPosition >= 82 && knobPosition < 109)) || (index === 1 && (knobPosition >= 75 && knobPosition < 82))),
										'practice-item--lightblue': ((index === 1 && (knobPosition >= 60 && knobPosition < 75)) || (index === 0 && (knobPosition >= 45 && knobPosition < 60))),
									}
									)}
									onClick={() => openPractice(el)}
								>
									{el.title}
								</li>
							)
						}
					</ul>

					<div className='hidden lg:flex lg:flex-col lg:order-4 xl:hidden'>
						<img src={qr} alt="" className={cn('qr-img')} />
						<div className={cn('mt-4','text-center')}>
							Еще больше <br />
							региональных практик <br />
							на <span>моифинансы.рф</span>
						</div>
					</div>
					<div className='flex order-4 lg:hidden'>
						<a className={cn('current-practice-btn', 'mb-4 w-full')} href='https://xn--80apaohbc3aw9e.xn--p1ai/regional-practices/'>
							Еще больше
							региональных практик
							на  моифинансы.рф
						</a>
					</div>

				</div>
			</div>

			<ModalWindow
				windowState={isModalOpened}
				setModal={setIsModalOpened}
				variant='practices'
				changeElement={changePractice}
				currentIndex={currentItemIndex}
			>
				{
					currentPracticesArray &&
					<div className='flex flex-col justufy-center'>
							<h1 className={cn('practice-title')}>{currentPracticesArray.titlePopUp}</h1>
							<div className={cn('overflow-x-auto w-full','list-wrap')}>
							{/* <div className='overflow-x-auto overflow-y-hidden '> */}
								<ul className={cn('flex items-center justify-start md:justify-center w-full gap-4 lg:gap-20')}>
									{
										currentPracticesArray.items.map((el, index) =>
											<li
												key={el.title}
												className={cn('practice-tab','cursor-pointer', {
													'practice-tab--active': currentPractice?.title === el.title,
												}, 'min-width-' + el.minWidth)}
												onClick={() => setCurrentPractice(el)}
											>
												{el.field}
											</li>
										)
									}
								</ul>
							</div>
						{
							currentPractice &&
							<div className='grid grid-cols-12'>
										<div className={cn('col-span-12 xl:col-span-2 flex flex-col sm:flex-row mb-8 xl:mb-0 xl:flex-col items-center justify-center gap-4', 'col-log-qr-style')}>
									<img src={currentPractice.image} alt="" className={cn('logo-img', 'lg:mb-3')} />
									<div className='hidden lg:block'>
										<span className={cn('current-practice-qr-txt')}>Подробнее о практике</span>
										<img src={currentPractice.qr} alt="" className={cn('qr-img', 'mb-3')}  />
									</div>

								</div>
										<div className='col-span-12 xl:col-span-10 md:pl-12'>
									<h2 className={cn('current-practice-title')}>
										{currentPractice.title}
									</h2>
									<div className={cn('current-practice-text')}>
										<p className='mb-10'>
											{currentPractice.text}
										</p>
										<div className='mb-3'>
											<span>Целевая аудитория:</span> {currentPractice.audience}
										</div>
										<div className='mb-3'>
											<span>Охват:</span> {currentPractice.coverage}
										</div>
										<div>
											<span>Разработчик:</span> {currentPractice.developer}
										</div>
									</div>
									<a className={cn('current-practice-btn','mb-4')} href={currentPractice.link}>
										Подробнее о практике
									</a>
								</div>
							</div>
						}
					</div>
				}
			</ModalWindow>
		</BgWrapper>
	);
};

export default BestPractices;
