import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './fonts.scss'; 
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import UserScoreContext from './context/ScoreContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<BrowserRouter>
		<UserScoreContext>
			<App />
		</UserScoreContext>
	</BrowserRouter>
);
