import pic1_1 from '../assets/images/BestProjects/1-1.webp';
import pic1_2 from '../assets/images/BestProjects/1-2.webp';
import pic1_3 from '../assets/images/BestProjects/1-3.webp';
import pic2_1 from '../assets/images/BestProjects/2-1.webp';
import pic2_2 from '../assets/images/BestProjects/2-2.webp';
import pic2_3 from '../assets/images/BestProjects/2-3.webp';
import pic3_1 from '../assets/images/BestProjects/3-1.webp';
import pic3_2 from '../assets/images/BestProjects/3-2.webp';
import pic3_3 from '../assets/images/BestProjects/3-3.webp';
import pic4_1 from '../assets/images/BestProjects/4-1.webp';
import pic4_2 from '../assets/images/BestProjects/4-2.webp';
import pic4_3 from '../assets/images/BestProjects/4-3.webp';
import pic5_1 from '../assets/images/BestProjects/5-1.webp';
import pic5_2 from '../assets/images/BestProjects/5-2.webp';
import pic5_3 from '../assets/images/BestProjects/5-3.webp';
import pic6_1 from '../assets/images/BestProjects/6-1.webp';
import pic6_2 from '../assets/images/BestProjects/6-2.webp';
import pic6_3 from '../assets/images/BestProjects/6-3.webp';
import pic7_1 from '../assets/images/BestProjects/7-1.webp';
import pic7_2 from '../assets/images/BestProjects/7-2.webp';
import pic7_3 from '../assets/images/BestProjects/7-3.webp';
import pic8_4 from '../assets/images/BestProjects/8-4.jpg';

import pic8_2 from '../assets/images/BestProjects/8-2.webp';
import pic8_3 from '../assets/images/BestProjects/8-3.webp';
import pic9_1 from '../assets/images/BestProjects/9-1.webp';
import pic9_2 from '../assets/images/BestProjects/9-2.webp';
import pic9_3 from '../assets/images/BestProjects/9-3.webp';
import pic10_1 from '../assets/images/BestProjects/10-1.webp';
import pic10_2 from '../assets/images/BestProjects/10-2.webp';
import pic10_3 from '../assets/images/BestProjects/10-3.webp';
import pic1 from '../assets/images/BestProjects/1-main.webp';
import pic2 from '../assets/images/BestProjects/2-main.webp';
import pic3 from '../assets/images/BestProjects/3-main.webp';
import pic4 from '../assets/images/BestProjects/4-main.webp';
import pic5 from '../assets/images/BestProjects/5-main.webp';
import pic6 from '../assets/images/BestProjects/6-main.webp';
import pic7 from '../assets/images/BestProjects/7-main.webp';
import pic8 from '../assets/images/BestProjects/8-main.webp';
import pic9 from '../assets/images/BestProjects/9-main.webp';
import pic10 from '../assets/images/BestProjects/10-main.webp';

export const mainData = [
	{
		id: 1,
		name: '«Бардовский сквер» сквер\n им. В.Грушина',
		location: 'Самарская область, г. Новокуйбышевск',
		pic: pic1_1,
	},
	{
		id: 2,
		name: '«Молодёжный центр в каждом селе»',
		location: 'ХМАО — Югра, деревня Лямина',
		pic: pic2_3,
	},
	{
		id: 3,
		name: '«Точка роста» (уроки финансовой грамотности)',
		location: 'г. Санкт-Петербург, г. Пушкин',
		pic: pic3_3,
	},
	{
		id: 4,
		name: 'Место отдыха для школьников «Зона 51»',
		location: 'ХМАО — Югра, г. Когалым',
		pic: pic4,
	},
	{
		id: 5,
		name: 'Караоке-клуб «Когда душа поет»',
		location: 'Тульская область, поселок Ширинский',
		pic: pic5_3,
	},
	{
		id: 6,
		name: 'Мини-музей Арктики «Белый медвежонок»',
		location: 'Архангельская область, г. Архангельск',
		pic: pic6_1,
	},
	{
		id: 7,
		name: 'Обустройство детской площадки',
		location: 'Ставропольский край, Георгиевский р-н, ст-ца Урухская',
		pic: pic7,
	},
	{
		id: 8,
		name: 'Пространство «Креатиум»',
		location: 'г. Санкт-Петербург',
		pic: pic8_3,
	},
	{
		id: 9,
		name: 'Сообщество волонтеров ШкИБ «Вас услышат!»',
		location: 'ХМАО — Югра, г. Когалым',
		pic: pic9_1,
	},
	{
		id: 10,
		name: 'Фестиваль «Лето. Пушкин. Молодежь»',
		location: 'Удмуртская Республика, село Красногорское',
		pic: pic10_3,
	},
];

export const modalData = [
	{
		id: 1,
		title: '«Бардовский сквер» сквер\n им. В.Грушина',
		place: 'Самарская область, г. Новокуйбышевск',
		description:
			'Здесь было создано пространство для уличных сценических выступлений, цель проекта — сохранение и развитие авторской песни, приобщение молодежи к музыке, поэзии, туризму. Сквер является напоминанием о подвиге, храбрости, неравнодушии.',
		amount: '2 247 человек',
		images: [ pic1_1, pic1_2, pic1_3, pic1],
	},
	{
		id: 2,
		title: '«Молодёжный центр в каждом селе»',
		place: 'ХМАО — Югра, деревня Лямина',
		description:
			'Здесь было создано пространство для взаимодействия сельской молодёжи и специалистов различных областей. В центре регулярно проводятся мастер-классы, воркшопы, творческие встречи.',
		amount: '311 человека',
		images: [ pic2_3, pic2_2, pic2, pic2_1],
	},
	{
		id: 3,
		title: '«Точка роста» (уроки финансовой\n грамотности)',
		place: 'г. Санкт-Петербург, г. Пушкин',
		description:
			'Авторы проекта организовали процесс обучения школьников базовым понятиям финансовой грамотности, в том числе через практическую деятельность, которая поможет детям адекватно относится к деньгам и потреблению. Проводятся образовательные мероприятия, закуплено игровое и интерактивное оборудование.',
		amount: '1 220 человек',
		images: [ pic3_3, pic3, pic3_2, pic3_1],
	},
	{
		id: 4,
		title: 'Место отдыха для школьников\n «Зона 51»',
		place: 'ХМАО — Югра, г. Когалым',
		description:
			'Здесь в одной из школ создали центр притяжения учащихся во время перемен и ожидания дополнительных занятий, где можно отдохнуть, пообщаться , подготовиться к занятиям, провести время. Штаб сообщества ШкИБ проводит в «Зоне 51» встречи, собрания, развлекательные мероприятия.',
		amount: '800 человек',
		images: [pic4, pic4_1, pic4_2, pic4_3],
	},
	{
		id: 5,
		title: 'Караоке-клуб «Когда душа поет»',
		place: 'Тульская область, поселок Ширинский',
		description:
			'Благодаря неравнодушным людям, здесь появился караоке-клуб для проведения мероприятий с участием жителей поселка в возрасте от 55 до 75 лет. Помещение караоке клуба отремонтировали, сделали концертную зону с подиумом-сценой и кулисами, купили оборудование: караоке-систему, экран с проектором, для зоны чаепития закупили столы, стулья, посуду.',
		amount: '225 человек',
		images: [ pic5_3, pic5, pic5_1, pic5_2],
	},
	{
		id: 6,
		title: 'Мини-музей Арктики\n «Белый медвежонок»',
		place: 'Архангельская область, г. Архангельск',
		description:
			'Организован музей, имитирующий пространство Арктики. Стены оформлены арктическими пейзажами. В музее размещены экспонаты, предметы быта, одежда и фотографии. Проводятся обзорных экскурсий для детей дошкольного и школьного возрастов, родителей. Для педагогов города организована работа по повышению профессиональной компетентности в области музейной педагогики.',
		amount: '2 700 человек',
		images: [pic6_1, pic6, pic6_3, pic6_2],
	},
	{
		id: 7,
		title: 'Обустройство детской площадки',
		place: 'Ставропольский край, Георгиевский р-н, ст-ца Урухская',
		description:
			'Здесь было создано комфортное и безопасное пространство для детей, где они могут отдыхать, развиваться, пребывать на улице на свежем воздухе, общаться со сверстниками. Ранее в станице не было современных детских площадок.',
		amount: '2 100 человек',
		images: [pic7, pic7_1, pic7_2, pic7_3],
	},
	{
		id: 8,
		title: 'Пространство «Креатиум»',
		place: 'г. Санкт-Петербург',
		description:
			'Организовано инновационное интерактивное творческое пространство в актовом зале школы с использованием 3D технологий: светодиодного LED-экрана, театральной проекционной сетки для реализации учебных, научных и творческих идей учеников и учителей в школе. Создана атмосфера настоящего кинотеатра с возможностью проводить собственные фестивали короткометражных фильмов.',
		amount: '2 799 человек',
		images: [pic8_3, pic8, pic8_4, pic8_2],
	},
	{
		id: 9,
		title: 'Сообщество волонтеров ШкИБ\n «Вас услышат!»',
		place: 'ХМАО — Югра, г. Когалым',
		description:
			'Создано сообщество волонтеров с целью вовлечения участников образовательного процесса (администрации школы, педагогов, учащихся и родителей) в активное преобразование окружающей школьной действительности.',
		amount: '762 человек',
		images: [pic9_1, pic9_2, pic9_3, pic9],
	},
	{
		id: 10,
		title: 'Фестиваль «Лето. Пушкин. Молодежь»',
		place: 'Удмуртская Республика, село Красногорское',
		description:
			'Здесь прошел фестиваль в современном молодежном формате, целью которого было приобщение молодого поколения к творческому наследию А.С. Пушкина. Фестиваль обращается к избранным страницам великих литературных и композиторских творений и вносит свой вклад в популяризацию как самого пушкинского наследия, так и его воплощения в шедеврах классической музыки.',
		amount: '300 человек',
		images: [pic10_3, pic10_1, pic10, pic10_2],
	},
];
