import { useState, useEffect, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';

export const useSplashAnimation = (loader, finger, rounds) => {
	const [isButtonClicked, setIsButtonClicked] = useState(false);

	useEffect(() => {
		gsap.registerPlugin(MotionPathPlugin);

		const loaderAnim = gsap.to(loader.current, {
			duration: 20,
			ease: 'linear',
			rotation: 360,
			repeat: -1,
		});
		const fingerAnim = gsap.to(finger.current, {
			duration: 3,
			ease: 'linear',
			rotation: 14,
			transformOrigin: '0% 0%',
			repeat: -1,
			yoyo: true,
			motionPath: 'M 25 98 L 19 101 c 15 1 6 -6 9 -9',
		});
		const roundsAnim = gsap.fromTo(
			rounds.current,
			{
				scale: 0,
				opacity: 1,
			},
			{
				scale: 2,
				duration: 1.5,
				opacity: 0,
				ease: 'power1.in',
				repeat: -1,
			}
		);

		return () => {
			loaderAnim.kill();
			fingerAnim.kill();
			roundsAnim.kill();
		};
	}, []);

	// разлетайки
	useEffect(() => {
		if (isButtonClicked) {
			const loaderAnim = gsap.to(loader.current, {
				duration: 0.7,
				ease: 'linear',
				scale: 10,
			});
			const fingerAnim = gsap.to(finger.current, {
				duration: 0.7,
				ease: 'linear',
				opacity: 0,
			});
			const roundsAnim = gsap.to(rounds.current, {
				duration: 0.7,
				ease: 'linear',
				opacity: 0,
			});
			return () => {
				loaderAnim.kill();
				fingerAnim.kill();
				roundsAnim.kill();
			};
		}
	}, [isButtonClicked]);

	return { setIsButtonClicked };
};

export const useMainPageAnimation = (map, crds, str, finances) => {
	useLayoutEffect(() => {
		const mapAnim = [...map.current.children].map((el, index) => {
			const duration = index % 4 === 0 ? 1.5 : 1;
			return gsap.from(el, {
				y: 1000,
				x: -2500,
				scale: 2,
				duration,
			});
		});

		const [crd1, crd2, crd3, crd4] = crds('a');
		const cardsTl = gsap.timeline();
		const cardsAnim = cardsTl
		.from(crd1, { y: 1000, duration: 1.5 })
		.from(crd2, { y: 1200, duration: 1.5 }, '-=1')
		.from(crd3, { y: 800, duration: 1.5 }, '-=1.5')
		.from(crd4, { y: 800, duration: 1.5 }, '-=1');

		const [str1, str2, str3, str4, str5, str6] = str('li');
		const stripsTl = gsap.timeline();
		const stripsAnim = stripsTl
		.from(str1, { y: -200, duration: 0.3 })
		.from(str2, { y: -200, duration: 0.3 })
		.from(str3, { y: -200, duration: 0.3 })
		.from(str4, { y: -200, duration: 0.3 })
		.from(str5, { y: -200, duration: 0.3 })
		.from(str6, { y: -200, duration: 0.3 });

		const logoAnim = gsap.from(finances.current, {
			duration: 1.5,
			ease: 'linear',
			yPercent: 100,
		});

		return () => {
			logoAnim.kill();
			stripsAnim.kill();
			cardsAnim.kill();
			mapAnim.forEach((el) => el.kill());
		};
	}, []);
};

export const useGameTitleAnimation = (owlRef, phraseRef) => {
	useEffect(() => {
		const owlTl = gsap.timeline();
		const owlAnim = owlTl
			.from(owlRef.current, {
				y: 600,
				x: 600,
				duration: 2,
			})
			.to(owlRef.current, {
				duration: 2,
				ease: 'linear',
				rotation: 7,
				transformOrigin: '100% 100%',
				repeat: -1,
				yoyo: true,
			});

		const phraseTl = gsap.timeline();
		const phraseAnim = phraseTl
			.from(phraseRef.current, {
				scale: 0,
				duration: 1,
				ease: 'power2.inOut',
			})
			.to(phraseRef.current, {
				duration: 1,
				ease: 'linear',
				rotation: -10,
				transformOrigin: '50% 50%',
				repeat: -1,
				yoyo: true,
			});

		return () => {
			phraseAnim.kill();
			owlAnim.kill();
		};
	}, []);
};
