import green2023 from '../assets/images/_UI/green2023.svg'
import pink2023 from '../assets/images/_UI/pink2023.svg'
import owl from '../assets/images/_UI/owl.svg'
import cubes from '../assets/images/_UI/cubes.svg'

export const cardsOldData = [
	{
		color: '#E6215A',
		url: 'pushkin',
		text: 'Квиз:\n Стихи, долги и карты.\n Что вы знаете лучше Пушкина?',
		btn: 'Пройти тест',
	},
	{
		color: '#00A555',
		url: 'finance-habits',
		text: 'Самый умный финансист. Викторина',
		btn: 'НАЧАТЬ',
	},
	{
		color: '#EF7D00',
		url: 'finance-quizzes',
		text: 'Финансовые \n загадки',
		btn: 'Проверить себя',
	},
	{
		color: '#31B7BC',
		url: 'tracker',
		text: 'Трекер финансового здоровья',
		btn: 'УЗНАТЬ БОЛЬШЕ',
	},
	{
		color: '#DB8D8D',
		url: 'best-practices',
		text: 'Лучшие региональные практики по финансовой грамотности – 2023',
		btn: 'УЗНАТЬ БОЛЬШЕ',
	},
	{
		color: '#014F9E',
		url: 'best-projects',
		text: 'Инициативное бюджетирование – 2022. Лучшие проекты',
		btn: 'УЗНАТЬ БОЛЬШЕ',
	},
];
export const cardsData = [
	{
		text: 'Лучшие региональные практики по финансовой грамотности',
		gradient: 'linear-gradient(180deg, #009757 0%, #02B76A 100%)',
		decor: green2023,
		url: '/best-practices',
	},
	{
		text: 'Инициативное бюджетирование',
		gradient: 'linear-gradient(180deg, #E52C62 0%, #F95283 100%)',
		decor: pink2023,
		url: '/best-projects',
	},
	{
		text: 'Игра-угадайка',
		gradient: 'linear-gradient(180deg, #336EAA 0%, #2782DF 100%)',
		decor: owl,
		url: '/game',
	},
	{
		text: 'Финансовые загадки',
		gradient: 'linear-gradient(180deg, #F07E00 0%, #FFAA4B 100%)',
		decor: cubes,
		url: '/finance-quizzes',
	},
];
